#dashboard {
    margin: 0;
    padding: 0;

    .pages {
        display: flex;
        justify-content: flex-start;
        overflow-x: scroll;
        align-items: center;

    }

    .reports {
        display: flex;
        justify-content: space-between;

        .fixed-card-filter {
            display: flex;
            justify-content: flex-start;
            overflow-x: scroll;
            align-items: center;
        }

        .custom-filter {
            display: flex;
            align-items: center;     
            justify-content: space-between;
        }
    }




    h6 {
        font-size: 12px;
    }

    p {
        font-size: 8px;
        font-weight: bold;
    }

    .reports-list-cards {
        min-width: 150px;
        padding: 5px;
        margin: 5px;
        border: 1px lightgrey solid;
        border-radius: 5px;
    }

    @media (max-width: 900px) {
        .pages {
            flex-direction: row;

        }

        .reports {
            flex-direction: column;
        }

        // .horizontal-scroll  {
        // //   flex-direction: row;
        // }
    }
}
/* Google Fonts Poppins */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@700&family=Tajawal:wght@700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
}
:root{
  --Primary:rgba(0,105,179,1);
  --Secondary: rgba(17,24,39,1);
  --green-color: #23a17c;
  --Orange-color:#e77136;
  --gray-color: #f4f4f4;
  --red-color: #f63d4f;
}
html,body {
	scroll-behavior: smooth;
}

main {
  min-height: 80vh;
}


.rating span {
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}

.carousel img {
  display: block;
  height: 250px;
  /*padding: 30px;
  margin: 40px; */
  /* border-radius: 50%; */
  margin-left: auto;
  margin-right: auto;
}
.carousel a {
  margin: 0 auto;
}
@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}


.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active{
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
.row {
  width:100%;
  /* outline: auto;
  outline-color: rgb(128, 30, 0);
  outline-width: 2px;
  outline-style: groove; */
  justify-content: center;
  align-content: center;
  text-align: center;
}

.col {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  /* outline: auto;
  outline-color: green;
  outline-width: 2px;
  outline-style: groove; */
  padding: 3px;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

div{
  justify-content: center;
  /* padding: 5px 5px; */
  box-sizing: border-box;
}

.scroll-component {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* start top-bg */
.top-bg{
  padding-top: 0;
background-position:  center;
background-size: 5.5rem;
background-repeat: no-repeat;
background-color: #fff;
}
/* end top-bg */

/* home-sections */
div.score-card{
  /* background-Color: "#FFFFFF"; */
  /* background-size: 'cover'; */
  /* background-position: left top; */
  /* background-repeat: repeat; */
  /* margin: 1%; */
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0;
  border-radius: 15px 15px;
  outline: auto;
  outline-color: grey;
  outline-width: 2px;
  outline-style: groove;
}

.score-card p{
  width: 100%;
  margin-top: 0;
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
}

.cart-image{
  width: 50px;
}

.banner{
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
  border-radius: 15px 15px;
}

div.bible-study-card{
  border-radius: 50px 15px;
  outline: auto;
  outline-color: green;
  outline-width: 2px;
  outline-style: groove;
}

.h3.score{
  margin-bottom:0 ;
  color: rgb(82, 17, 143);
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
}



.redeem-button {
  width: 100%;
  margin: 0;
  padding: 5px;
  align-content: center;
  text-align: center;
  /* outline: auto; */
  /* outline-color: green; */
  /* outline-width: 2px; */
  /* outline-style: groove; */
}



/* end home-sections */




/* start events card  */
.scroll-menu{
  width: 100%;
  height: fit-content;

  /* used to scroll text */
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  /* keep scrolling when finger or cursor scrolling */
  -webkit-overflow-scrolling: touch;
}

.product-card{
  align-content: center;
  text-align: center;
  margin: 5px;
  padding: 5px;
  border-radius: 15px 15px;
  background-color: rgb(255, 255, 255);
  /* min-width: 1rem; */
  /* min-height: 20rem; */
  max-width: 15rem;
  max-height: 20rem;
  float: none;
  display: inline-block;
  outline-color: rgb(180, 238, 180);
  outline-width: 2px;
  outline-style: groove;
}

.attendance-card{
  align-content: center;
  text-align: center;
  margin: 4px;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 15px 15px;
  background-color: rgb(255, 255, 255);
  width: 100%;
  font-size:"4vw";
  float: none;
  display: inline-block;
  outline-color: rgb(180, 238, 180);
  outline-width: 2px;
  outline-style: groove;
}

.attendance-card p{
  font-size: 12px;
}

.attendance-card i{
  font-size: 35px;
}

.attendance-card span i{
  font-size: 15px;
}


/* to hide the scrollbars */
::-webkit-scrollbar{
  display: none;
}

/* end events card  */


/* start modal styling */
/* .modal{
  border-radius: 15px 15px;
} 

.modal.serverd-by{
  height: fit-content;
} */
/* end modal styling */

/* start clickable-link */

/* end clickable-link */

/* this to hide the button of upload */
input[type="file"] {
  display: none;
}

.custom-file-upload {
  width: 80px;
  height: 80px;
  border-radius: 100px 100px;
  border: 1px solid #ccc;
  display: inline-block;
  cursor: pointer;
}



.update-create-form-group{
  text-align: right;
  /* outline: auto; */
  /* outline-color: green; */
  /* outline-width: 2px; */
  /* outline-style: groove; */
}

.required:after {
  content:" *";
  color: red;
}

.AMEN:after {
  content:" **";
  color: green;
}

.MOSAED:after {
  content:" *";
  color: green;
}

.update-create-form-control-right{
  direction: rtl;
  /* text-align: right; */
  outline: auto;
  outline-color: rgb(128, 128, 128);
  outline-width: 2px;
  outline-style: groove;
}

.outline{
  outline: auto;
  outline-color: rgb(128, 128, 128);
  outline-width: 2px;
  outline-style: groove;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.update-create-form-label{
  margin: 10px 0px;
  padding: 0px;
}


/* start Side navigation */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.1s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
  transition: 0.3s;
  text-align: left;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

/* end Side navigation */

/* start link container  */
.link-container{
  width: 100%;
}

.module.card {
  width: 100%;
  border-radius: "15px 15px";
  outline-width: "1px";
  width: 250px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.module.card .large-size{
  font-size: 35px;
}

.module.card:hover {
  background-color: rgb(244, 204, 144);
}

div.absolute {
  position: absolute;
  bottom: 10%;
} 

div.relative {
  position: absolute;
  bottom: 15%;
} 


.scroll-vertical {
  /* float:left; */
/* width:1000px; */
overflow-y: auto;
max-height: 400px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aspect-ratio-box {
  position: relative;
  width: 100%;
}

.aspect-ratio-box .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Example usage with a 16:9 aspect ratio */
.aspect-ratio-box-16-9 {
  padding-top: 56.25%;
}

/* Example usage with a 16:9 aspect ratio */
.aspect-ratio-box-1-1 {
  padding-top: 100%;
}